
import { defineComponent } from 'vue';
import UIController from '@/clients/ui';

export default defineComponent({
  setup() {
    const copyText = () =>
      navigator.clipboard.writeText('support@artificial.com');

    const useTecanBranding =
      UIController.Instance.hasFeatureFlag('USE_TECAN_BRANDING');

    return {
      copyText,
      useTecanBranding,
    };
  },
});
