
import { defineComponent, computed, ref, Ref } from 'vue';
import moment from 'moment';
import OpsController from '@/clients/ops/controller';
import { createNameHash } from '../../../utils';
import { Action } from '@/clients/action/model';
import Wizard from '../Wizard.vue';
import WorkflowRequest from '../WorkflowRequest.vue';
import ReviewAndSubmit from '../../ReviewAndSubmit.vue';
import SchedulingModule from '../SchedulingModule.vue';
import { Job } from '@/clients/ops/model';

// interface Props {
//   labId: string;
// }

export default defineComponent({
  components: {
    Wizard,
  },
  props: {
    labId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedAction: Ref<Action | null> = ref(null);
    const actionName = ref('');
    const constraint: Ref<'asap' | 'time'> = ref('asap');
    const date = ref(new Date());
    const time = ref(new Date());

    const createRequestAndSchedule = async () => {
      if (selectedAction.value) {
        const newJob = await OpsController.Instance.dispatchCreateJobFromAction(
          // @ts-ignore
          actionName.value,
          '',
          selectedAction.value.id,
          undefined,
          []
        );

        if (newJob) {
          const schedTime = new Date(time.value);
          const schedDate = moment(date.value);
          schedDate.set('hours', schedTime.getHours());
          schedDate.set('minutes', schedTime.getMinutes());
          const scheduleId = await OpsController.Instance.dispatchScheduleJob(
            newJob.id,
            [newJob.id],
            constraint.value,
            (schedDate.valueOf() / 1000).toString(),

            ''
          );

          if (scheduleId) {
            close(newJob);
          }
        }
      }
    };

    const assistantWizardSteps = computed(() => [
      {
        id: 'workflowSelect',
        name: 'Select Assistant',
        props: {
          labId: props.labId,
          selectedAction: selectedAction.value,
          isWorkflow: false,
        },
        component: WorkflowRequest,
        action: async (newAction) => {
          selectedAction.value = newAction;
          actionName.value = await createNameHash('ASSISTANT');
        },
        displayCondition: true,
        disabledCondition: false,
        hideNext: true,
        fullHeight: true,
      },
      {
        id: 'scheduleAssistant',
        name: 'Schedule',
        props: {
          isWorkflow: !selectedAction.value?.assistant,
          selectedAction: selectedAction.value,
        },
        component: SchedulingModule,
        action: (data: {
          constraint: 'asap' | 'time';
          date: Date;
          time: Date;
        }) => {
          console.log(data);
          date.value = data.date;
          time.value = data.time;
          constraint.value = data.constraint;
        },
        displayCondition: true,
        disabledCondition: false,
        hasCenterContent: true,
      },
      {
        id: 'reviewAndSubmit',
        name: 'Review & Submit',
        props: {
          name: actionName.value,
          actionId: selectedAction.value?.id,
          strategy: constraint.value,
          scheduledTime: time.value,
          scheduledDate: date.value,
        },
        component: ReviewAndSubmit,
        displayCondition: true,
        disabledCondition: false,
        hasCenterContent: true,
      },
    ]);

    const close = (newJob?: Job) => emit('close', newJob);

    return {
      assistantWizardSteps,
      selectedAction,
      close,
      createRequestAndSchedule,
    };
  },
});
