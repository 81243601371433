import { gql } from '@apollo/client/core';
import { User, nullUser } from './model';
import { apollo } from '../service.apollo';

export async function getUsers(): Promise<User[]> {
  const result = await apollo.query<{ allUsers: User[] }>({
    query: gql`
      query GetUsers {
        allUsers {
          id
          name
          email
          profileImage
          policies {
            id
            userId
            companyId
            orgId
            policyActions {
              id
              action
            }
          }
          roleIds
        }
      }
    `,
  });

  return result.data.allUsers.map((u) => ({
    ...nullUser(),
    ...u,
  }));
}

export async function getCurrentUser(): Promise<User> {
  const result = await apollo.query<{ user: User }>({
    query: gql`
      query GetCurrentUser {
        user {
          id
          name
          email
          orgId
          policies {
            id
            orgId
            policyActions {
              id
              action
            }
          }
          roleIds
          totpEnabled
        }
      }
    `,
  });

  return {
    ...nullUser(),
    ...result.data.user,
  };
}

export async function getArtificialDevelopers(): Promise<string[]> {
  const result = await apollo.query<{ artificialDevelopers: User[] }>({
    query: gql`
      query GetArtificialDevelopers {
        artificialDevelopers {
          id
        }
      }
    `,
  });

  return result.data.artificialDevelopers.map((u) => u.id);
}

export async function promoteMember(userId) {
  const result = await apollo.mutate<{ id }>({
    variables: { userId },
    mutation: gql`
      mutation PromoteUserToMember($userId: ID!) {
        promoteUserNormal(userId: $userId) {
          id
        }
      }
    `,
  });

  return result.data?.id;
}

export async function promoteUserAdmin(userId) {
  const result = await apollo.mutate<{ id }>({
    variables: { userId },
    mutation: gql`
      mutation PromoteUserToAdmin($userId: ID!) {
        promoteUserAdmin(userId: $userId) {
          id
        }
      }
    `,
  });

  return result.data?.id;
}

export async function demoteUserPolicy(id) {
  const result = await apollo.mutate({
    variables: {
      id,
    },
    mutation: gql`
      mutation DemoteUserPolicy($id: ID!) {
        deletePolicy(id: $id)
      }
    `,
  });

  return result;
}

export async function deleteUser(id) {
  const result = await apollo.mutate({
    variables: {
      userId: id,
    },
    mutation: gql`
      mutation DeleteUser($userId: ID!) {
        deleteUser(userId: $userId)
      }
    `,
  });

  return result;
}

export async function createTotp(): Promise<string> {
  const result = await apollo.mutate<{ createTOTP: string }>({
    mutation: gql`
      mutation CreateTotp {
        createTOTP
      }
    `,
  });

  return result.data?.createTOTP || '';
}

export async function deleteTotp(token: string): Promise<boolean> {
  try {
    const result = await apollo.mutate<{ deleteTOTP: boolean }>({
      variables: { token },
      context: {
        ignoreErrorNotifications: true,
      },
      mutation: gql`
        mutation DeleteTotp($token: String) {
          deleteTOTP(token: $token)
        }
      `,
    });

    return result.data?.deleteTOTP || false;
  } catch (_) {
    return false;
  }
}

export async function verifyTotp(token: string): Promise<boolean> {
  try {
    const result = await apollo.mutate<{ verifyTOTP: boolean }>({
      variables: { token },
      context: {
        ignoreErrorNotifications: true,
      },
      mutation: gql`
        mutation VerifyTotp($token: String) {
          verifyTOTP(token: $token)
        }
      `,
    });

    return result.data?.verifyTOTP || false;
  } catch (_) {
    return false;
  }
}
