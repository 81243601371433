
import { defineComponent, computed, ref } from 'vue';
import { UserRoles, User } from '@/clients/users/model';
import UserController, { getUserRole } from '@/clients/users/controller';
import RoleSelector from './RoleSelector.vue';
import ArtificialIcon from '@/assets/icons/artificial.svg';
import { getUserRoleLabel } from './utils';

export default defineComponent({
  components: {
    RoleSelector,
  },
  setup() {
    const addMemberDialogVisible = ref(false);
    const userIdToAdd = ref<number>();

    const users = computed(() => UserController.Instance.usersWithAccess);
    const usersWithoutAccess = computed(
      () => UserController.Instance.usersWithoutAccess
    );

    const fallBack = (e) => (e.target.src = ArtificialIcon);

    const currentUser = computed(() => UserController.Instance.currentUser);
    const isAdmin = computed(() => {
      return getUserRole(currentUser.value) === UserRoles.ADMIN;
    });

    const handleAddMember = async () => {
      if (!userIdToAdd.value) return;
      await UserController.Instance.dispatchPromoteUserMember(
        userIdToAdd.value
      );
      addMemberDialogVisible.value = false;
      userIdToAdd.value = undefined;
    };

    return {
      UserRoles,
      users,
      usersWithoutAccess,
      currentUser,
      fallBack,
      getUserRoleLabel,
      isAdmin,
      addMemberDialogVisible,
      userIdToAdd,
      handleAddMember,
    };
  },
});
