import { reactive } from 'vue';
import * as Apollo from './service.apollo';
import { User, UserRoles, nullUser } from './model';
import DeferredPromise from '../DeferredPromise';

interface StateInterface {
  users: User[];
  currentUser: User;
  artificialDeveloperIds: string[];
  isActingAsArtificialUser: boolean;
}

export const getUserRole = (user: User): UserRoles => {
  if (!user.roleIds || !user.roleIds.length) {
    return UserRoles.NONE;
  }

  if (user.roleIds.includes(UserRoles.ADMIN)) {
    return UserRoles.ADMIN;
  } else if (user.roleIds.includes(UserRoles.MEMBER)) {
    return UserRoles.MEMBER;
  } else {
    return UserRoles.NONE;
  }
};

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;
  private allUsersRetrieved: DeferredPromise<void> = new DeferredPromise();

  private constructor() {
    /*
     * STATE
     */
    this.state = reactive({
      users: [],
      currentUser: nullUser(),
      artificialDeveloperIds: [],
      isActingAsArtificialUser:
        localStorage.getItem('isActingAsArtificialUser') !== 'false',
    });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  /*
   * ACTIONS/MUTATIONS
   */

  async dispatchGetWorld() {
    await Promise.all([
      this.dispatchGetUsers(),
      this.dispatchGetCurrentUser(),
      this.dispatchGetArtificialDevelopers(),
    ]);
    this.allUsersRetrieved.resolve();
  }

  async dispatchGetUsers() {
    this.state.users = await Apollo.getUsers();
  }

  async dispatchGetCurrentUser() {
    this.state.currentUser = await Apollo.getCurrentUser();
  }

  async dispatchGetArtificialDevelopers() {
    this.state.artificialDeveloperIds = await Apollo.getArtificialDevelopers();
  }

  /**
   * Demote a user?
   * MUTATION/IDEMPOTENT
   * @param policyId the ID of the policy
   */
  async dispatchDemoteUser(policyId) {
    await Apollo.demoteUserPolicy(policyId);
    await this.dispatchGetUsers();
  }

  /**
   * Delete a user
   * MUTATION/IDEMPOTENT
   * @param userId the ID of the user to delete
   */
  async dispatchDeleteUser(userId) {
    await Apollo.deleteUser(userId);
    await this.dispatchGetUsers();
  }

  /**
   * Promote a user to member
   * MUTATION/IDEMPOTENT
   * @param userId the ID of the user
   */
  async dispatchPromoteUserMember(userId) {
    await Apollo.promoteMember(userId);
    await this.dispatchGetUsers();
  }

  /**
   * Promote a user to admin
   * MUTATION/IDEMPOTENT
   * @param userId the ID of the user
   */
  async dispatchPromoteUserAdmin(userId) {
    await Apollo.promoteUserAdmin(userId);
    await this.dispatchGetUsers();
  }

  /**
   * Create a TOTP secret
   * @returns a TOTP secret
   */
  async dispatchCreateTotp(): Promise<string> {
    return Apollo.createTotp();
  }

  /**
   * Delete a TOTP secret
   * @param token the TOTP token
   * @returns true if the TOTP secret was deleted
   */
  async dispatchDeleteTotp(token: string): Promise<boolean> {
    const deleted = await Apollo.deleteTotp(token);
    if (deleted) {
      this.state.currentUser.totpEnabled = false;
    }
    return deleted;
  }

  /**
   * Verify a TOTP token
   * @param token the TOTP token
   * @returns true if the token is valid
   */
  async dispatchVerifyTotp(token: string): Promise<boolean> {
    const verified = await Apollo.verifyTotp(token);
    if (verified) {
      this.state.currentUser.totpEnabled = true;
    }
    return verified;
  }

  /*
   * GETTERS
   */
  get allUsersRetrievedPromise(): DeferredPromise<void> {
    return this.allUsersRetrieved;
  }
  get users() {
    return this.state.users;
  }

  get usersWithAccess() {
    return this.state.users.filter((u) =>
      [UserRoles.ADMIN, UserRoles.MEMBER].includes(getUserRole(u))
    );
  }

  get usersWithoutAccess() {
    return this.state.users.filter((u) => getUserRole(u) === UserRoles.NONE);
  }

  get currentUser() {
    return this.state.currentUser;
  }

  get isActingAsArtificialUser() {
    return (
      this.isCurrentUserArtificialDeveloper() &&
      this.state.isActingAsArtificialUser
    );
  }

  set isActingAsArtificialUser(value: boolean) {
    if (!this.isCurrentUserArtificialDeveloper()) return;
    this.state.isActingAsArtificialUser = value;
    localStorage.setItem('isActingAsArtificialUser', value ? '' : 'false');
  }

  getUser(id: string): User {
    return this.state.users.find((u) => u.id === id) || nullUser();
  }

  isUserArtificialDeveloper(id: string): boolean {
    return this.state.artificialDeveloperIds.includes(id);
  }

  isCurrentUserArtificialDeveloper(): boolean {
    return this.isUserArtificialDeveloper(this.state.currentUser.id);
  }
}
