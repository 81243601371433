import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73623822"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "wizard",
  id: "wizard"
}
const _hoisted_2 = {
  key: 0,
  class: "error-message"
}
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wizard_item = _resolveComponent("wizard-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['top-gradient', { 'scrolled-down': _ctx.isScrolledDown }])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(['wizard-body', { 'in-review': _ctx.inReview }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayableSteps, (step, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass([
          'step-wrapper',
          {
            active: _ctx.isActive(index),
            'review-step': _ctx.isReviewStep(index) && _ctx.isActive(index),
            'full-height': _ctx.isActive(index) && step.fullHeight && !_ctx.inReview,
          },
        ]),
          key: step.id
        }, [
          _createVNode(_component_wizard_item, {
            active: _ctx.isActive(index),
            inReview: _ctx.inReview,
            completed: _ctx.currentStep > index,
            step: index,
            totalSteps: _ctx.displayableSteps.length,
            name: step.name,
            component: step.component,
            props: step.props,
            action: step.action,
            buttonText: step.buttonText,
            disabledCondition: step.disabledCondition,
            hideNext: step.hideNext,
            stepErrors: _ctx.stepErrors,
            repeatable: step.repeatable,
            onStepForward: _ctx.stepForward,
            onRepeatStep: _ctx.repeatStep,
            onSetCurrentStep: _ctx.setCurrentStep
          }, null, 8, ["active", "inReview", "completed", "step", "totalSteps", "name", "component", "props", "action", "buttonText", "disabledCondition", "hideNext", "stepErrors", "repeatable", "onStepForward", "onRepeatStep", "onSetCurrentStep"]),
          (_ctx.errorMessage && index === _ctx.displayableSteps.length - 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createTextVNode(" There was an error submitting the request. You can review the request to try submitting again, or cancel the new request. "),
                _createElementVNode("div", null, _toDisplayString(_ctx.errorMessage), 1),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("button", {
                    class: "continue",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clearError && _ctx.clearError(...args)))
                  }, " Continue Editing "),
                  (_ctx.cancelAction)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "cancel",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleCancelAction && _ctx.handleCancelAction(...args)))
                      }, " Cancel "))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([
        'bottom-gradient',
        {
          'scrolled-up': _ctx.isScrolledUp,
          'scrolled-up-review': _ctx.isScrolledUpReview && _ctx.inReview,
        },
      ])
    }, null, 2)
  ]))
}