import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-262c1a7d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "readonly-field"
}
const _hoisted_2 = { key: 8 }
const _hoisted_3 = { style: {"display":"flex","margin":"auto"} }
const _hoisted_4 = {
  key: 12,
  class: "required-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WarnTriangleFilled = _resolveComponent("WarnTriangleFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_InfoFilled = _resolveComponent("InfoFilled")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_check_square = _resolveComponent("check-square")!
  const _component_Document = _resolveComponent("Document")!
  const _component_Upload = _resolveComponent("Upload")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_simple_array_input = _resolveComponent("simple-array-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["request-field", { 'request-field-small': _ctx.small }])
  }, [
    (_ctx.renderLabel)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['label', { 'inline-label': _ctx.forceReadOnly }]),
          tabindex: "-1"
        }, [
          _createTextVNode(_toDisplayString(_ctx.label || _ctx.name) + " ", 1),
          (_ctx.inError)
            ? (_openBlock(), _createBlock(_component_el_tooltip, {
                key: 0,
                content: _ctx.errorMessage,
                placement: "top",
                tabindex: -1
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, {
                    class: "in-error-icon",
                    tabindex: "-1"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_WarnTriangleFilled)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["content"]))
            : _createCommentVNode("", true),
          (_ctx.field.description)
            ? (_openBlock(), _createBlock(_component_el_tooltip, {
                key: 1,
                content: _ctx.field.description,
                tabindex: -1
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, {
                    class: "info-icon",
                    tabindex: "-1"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_InfoFilled)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["content"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.forceReadOnly || (_ctx.field.readOnly && !_ctx.overrideReadonly))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.formattedText), 1))
      : (_ctx.raw)
        ? (_openBlock(), _createBlock(_component_el_input, {
            key: 2,
            type: "textarea",
            rows: 4,
            modelValue: _ctx.textInput,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textInput) = $event)),
            onChange: _ctx.updateRaw,
            spellcheck: "false",
            class: _normalizeClass({ 'in-error': _ctx.inError })
          }, null, 8, ["modelValue", "onChange", "class"]))
        : (_ctx.inputType === _ctx.WidgetType.TEXT)
          ? (_openBlock(), _createBlock(_component_el_input, {
              key: 3,
              modelValue: _ctx.textInput,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.textInput) = $event)),
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.valueChanged($event !== '' ? $event : null))),
              size: _ctx.small ? 'small' : '',
              class: _normalizeClass({ 'in-error': _ctx.inError }),
              spellcheck: "false"
            }, null, 8, ["modelValue", "size", "class"]))
          : (_ctx.inputType === _ctx.WidgetType.PASSWORD)
            ? (_openBlock(), _createBlock(_component_el_input, {
                key: 4,
                modelValue: _ctx.textInput,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.textInput) = $event)),
                onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.valueChanged($event !== '' ? $event : null))),
                size: _ctx.small ? 'small' : '',
                class: _normalizeClass({ 'in-error': _ctx.inError }),
                spellcheck: "false",
                type: "password",
                "show-password": "",
                autocomplete: "off"
              }, null, 8, ["modelValue", "size", "class"]))
            : (_ctx.inputType === _ctx.WidgetType.NUMERIC)
              ? (_openBlock(), _createBlock(_component_el_input_number, {
                  key: 5,
                  modelValue: _ctx.numericInput,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.numericInput) = $event)),
                  min: _ctx.numericMin,
                  max: _ctx.numericMax,
                  step: _ctx.numericStep,
                  onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.valueChanged($event))),
                  size: _ctx.small ? 'small' : '',
                  controls: !_ctx.small,
                  class: _normalizeClass({ 'in-error': _ctx.inError })
                }, null, 8, ["modelValue", "min", "max", "step", "size", "controls", "class"]))
              : (_ctx.inputType === _ctx.WidgetType.SWITCH)
                ? (_openBlock(), _createBlock(_component_el_switch, {
                    key: 6,
                    modelValue: _ctx.booleanInput,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.booleanInput) = $event)),
                    onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.valueChanged($event))),
                    size: _ctx.small ? 'small' : '',
                    disabled: _ctx.forceReadOnly || (_ctx.field.readOnly && !_ctx.overrideReadonly),
                    class: _normalizeClass({ 'in-error': _ctx.inError })
                  }, null, 8, ["modelValue", "size", "disabled", "class"]))
                : (_ctx.inputType === _ctx.WidgetType.CHECKBOX)
                  ? (_openBlock(), _createBlock(_component_check_square, {
                      key: 7,
                      value: _ctx.booleanInput,
                      onInput: _cache[9] || (_cache[9] = ($event: any) => {
        _ctx.booleanInput = $event;
        _ctx.valueChanged($event);
      }),
                      disabled: _ctx.forceReadOnly || (_ctx.field.readOnly && !_ctx.overrideReadonly),
                      class: _normalizeClass({ 'in-error': _ctx.inError })
                    }, null, 8, ["value", "disabled", "class"]))
                  : (_ctx.inputType === _ctx.WidgetType.FILE)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("label", {
                          for: "file-picker",
                          class: _normalizeClass([
          { 'custom-file-upload': _ctx.small },
          { 'custom-file-upload-lg': !_ctx.small },
          { 'in-error': _ctx.inError },
          { 'drag-target': _ctx.fileDragging },
          { 'file-selected': _ctx.fileInput },
        ]),
                          onDragenter: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.fileDragEnter && _ctx.fileDragEnter(...args))),
                          onDragover: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.fileDragOver && _ctx.fileDragOver(...args))),
                          onDragleave: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.fileDragExit && _ctx.fileDragExit(...args))),
                          onDrop: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.fileDrop && _ctx.fileDrop(...args)))
                        }, [
                          _createElementVNode("div", _hoisted_3, [
                            (_ctx.small)
                              ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_Document)
                                  ]),
                                  _: 1
                                }))
                              : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_Upload)
                                  ]),
                                  _: 1
                                })),
                            _createElementVNode("div", null, _toDisplayString(_ctx.fileName), 1)
                          ])
                        ], 34),
                        _createElementVNode("input", {
                          id: "file-picker",
                          class: "inputfile",
                          type: "file",
                          onChange: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.fileSelected && _ctx.fileSelected(...args)))
                        }, null, 32)
                      ]))
                    : (_ctx.inputType === _ctx.WidgetType.SELECT)
                      ? (_openBlock(), _createBlock(_component_el_select, {
                          key: 9,
                          modelValue: _ctx.selectVal,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.selectVal) = $event)),
                          onChange: _cache[16] || (_cache[16] = ($event: any) => (_ctx.valueChanged($event))),
                          size: _ctx.small ? 'small' : '',
                          disabled: _ctx.forceReadOnly || (_ctx.field.readOnly && !_ctx.overrideReadonly),
                          class: _normalizeClass({ 'in-error': _ctx.inError })
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enumVals, (val) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: val.id,
                                label: val.name,
                                value: val.id,
                                disabled: val.disabled
                              }, {
                                default: _withCtx(() => [
                                  (val.disabled && val.description)
                                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                        key: 0,
                                        content: val.description,
                                        placement: "right"
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("span", {
                                            class: _normalizeClass({ 'selection-disabled': val.disabled })
                                          }, _toDisplayString(val.name), 3)
                                        ]),
                                        _: 2
                                      }, 1032, ["content"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "size", "disabled", "class"]))
                      : (_ctx.inputType === _ctx.WidgetType.DATE)
                        ? (_openBlock(), _createBlock(_component_el_date_picker, {
                            key: 10,
                            modelValue: _ctx.dateInput,
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.dateInput) = $event)),
                            onChange: _cache[18] || (_cache[18] = ($event: any) => (_ctx.valueChanged($event))),
                            size: _ctx.small ? 'small' : '',
                            readonly: _ctx.forceReadOnly || (_ctx.field.readOnly && !_ctx.overrideReadonly),
                            class: _normalizeClass({ 'in-error': _ctx.inError })
                          }, null, 8, ["modelValue", "size", "readonly", "class"]))
                        : (_ctx.inputType === _ctx.WidgetType.ARRAY)
                          ? (_openBlock(), _createBlock(_component_simple_array_input, {
                              key: 11,
                              type: _ctx.arraySubType,
                              "initial-value": _ctx.initialValue,
                              "onUpdate:array": _cache[19] || (_cache[19] = ($event: any) => (_ctx.valueChanged($event))),
                              small: _ctx.small,
                              class: _normalizeClass({ 'in-error': _ctx.inError })
                            }, null, 8, ["type", "initial-value", "small", "class"]))
                          : _createCommentVNode("", true),
    (_ctx.required && _ctx.renderLabel && !_ctx.forceReadOnly)
      ? (_openBlock(), _createElementBlock("label", _hoisted_4, "* Required"))
      : _createCommentVNode("", true)
  ], 2))
}