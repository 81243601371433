
import {
  defineComponent,
  computed,
  ref,
  ComputedRef,
  Ref,
  onMounted,
  onUnmounted,
  PropType,
  watch,
} from 'vue';
import { Component } from 'vue';
import UIController from '@/clients/ui';
import WizardItem from './WizardItem.vue';

export interface Step {
  id: string;
  name: string;
  buttonText?: string;
  component: Component;
  props: Record<string, unknown>;
  displayCondition: boolean | (() => boolean) | ComputedRef<boolean>;
  disabledCondition: boolean | (() => boolean) | ComputedRef<boolean>;
  fullHeight?: boolean;
  repeatable?: boolean;
  action?: (parameters) => void;
  repeat?: (...args: never[]) => void;
  stepForward?: (...args: never[]) => Promise<string[]>; // return a non-empty array of error messages to prevent navigation
  stepBackward?: (...args: never[]) => Promise<string[]>; // return a non-empty array of error messages to prevent navigation
  setStepActive?: () => void;
  hideNext: boolean;
  hasCenterContent?: boolean;
}

// interface Props {
//   steps: Step[];
//   completionAction?: () => void;
//   cancelAction?: () => void;
// }

export default defineComponent({
  components: {
    WizardItem,
  },
  props: {
    steps: {
      type: Array as PropType<Step[]>,
      required: true,
    },
    completionAction: Function,
    cancelAction: Function,
  },
  setup(props, { emit }) {
    const errorMessage = ref('');

    const displayableSteps = computed(() =>
      props.steps.filter((s: Step) => s.displayCondition)
    );

    const setInitialCurrentStep = () => {
      let foundStartingStep: number | null = null;
      let i = 0;
      while (foundStartingStep === null && i < displayableSteps.value.length) {
        if (displayableSteps.value[i].displayCondition) {
          foundStartingStep = i;
        }
        i++;
      }
      return foundStartingStep !== null ? foundStartingStep : i;
    };

    const currentStep = ref(setInitialCurrentStep());
    const lastStep = ref(0);
    const isReviewStep = (index) => index === displayableSteps.value.length - 1;
    const stepErrors: Ref<string[]> = ref([]);

    const nextStep = computed(() => {
      let index = currentStep.value + 1;
      while (
        index < displayableSteps.value.length &&
        !displayableSteps.value[index].displayCondition
      ) {
        index++;
      }
      if (
        index === displayableSteps.value.length ||
        !displayableSteps.value[index].displayCondition
      ) {
        return displayableSteps.value.length;
      }
      return index;
    });

    const isActive = (index: number): boolean => {
      return index === currentStep.value;
    };

    const inReview = computed(
      () => currentStep.value === displayableSteps.value.length - 1
    );

    const stepForward = async () => {
      if (nextStep.value < displayableSteps.value.length) {
        if (displayableSteps.value[currentStep.value]?.stepForward) {
          stepErrors.value =
            (await displayableSteps.value[
              currentStep.value
            ]?.stepForward?.()) || [];
          if (stepErrors.value.length === 0) {
            lastStep.value = currentStep.value;
            currentStep.value = nextStep.value;
          }
        } else {
          lastStep.value = currentStep.value;
          currentStep.value = nextStep.value;
          stepErrors.value = [];
        }
      } else {
        //If a completion step exists, try it. If an error occurs:
        // 1) display an error message for any errors.
        // 2) user can go back and edit the job details OR
        // 3) kill the current job they are working on
        if (props.completionAction) {
          try {
            await props.completionAction();
            emit('steps-complete');
          } catch (e) {
            setCurrentStep(displayableSteps.value.length - 1);
            if (e instanceof Error) {
              errorMessage.value = e.message;
            }
          }
        } else {
          emit('steps-complete');
        }
      }
    };
    const stepBackwards = () => {
      currentStep.value = lastStep.value;
      if (lastStep.value > 0) {
        stepErrors.value = [];
        lastStep.value -= 1;
      }
      displayableSteps.value[lastStep.value]?.stepBackward?.();
    };

    const setCurrentStep = (index) => {
      if (index < currentStep.value) {
        stepErrors.value = [];
        currentStep.value = index;
        displayableSteps.value[currentStep.value]?.setStepActive?.();
        if (currentStep.value > 0) {
          lastStep.value = currentStep.value - 1;
        } else {
          lastStep.value = 0;
        }
      }
    };

    const repeatStep = async () => {
      try {
        await displayableSteps.value[currentStep.value]?.repeat?.();
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          errorMessage.value = e.message;
        }
      }
    };

    const isScrolledDown = ref(false);
    const isScrolledUp = ref(false);
    const isScrolledUpReview = ref(false);

    // To control showing a gradient when the first/last/review steps are
    // scrolled out of view we can use a set of observers.
    const observerFirstStep = new IntersectionObserver(
      (elements) => {
        if (elements[0].isIntersecting === true) {
          isScrolledDown.value = false;
        } else {
          isScrolledDown.value = true;
        }
      },
      { threshold: [0.8] }
    );

    const observeReview = new IntersectionObserver(
      (elements) => {
        if (elements[0].isIntersecting === true && !inReview.value) {
          isScrolledUp.value = false;
        } else {
          isScrolledUp.value = true;
        }
      },
      { threshold: [0.1] }
    );

    const observeLastStep = new IntersectionObserver(
      (elements) => {
        if (elements[0].isIntersecting === true && inReview.value) {
          isScrolledUpReview.value = false;
        } else if (inReview.value) {
          isScrolledUpReview.value = true;
        }
      },
      { threshold: [0.8] }
    );

    let firstStepElement: HTMLElement | null = null;
    let reviewStepElement: HTMLElement | null = null;
    let lastStepElement: HTMLElement | null = null;
    onMounted(() => {
      firstStepElement = document.getElementById('item-0');
      reviewStepElement = document.getElementById(
        `item-${displayableSteps.value.length - 1}`
      );
      lastStepElement = document.getElementById(
        `item-${displayableSteps.value.length - 2}`
      );
      if (firstStepElement && reviewStepElement && lastStepElement) {
        observerFirstStep.observe(firstStepElement);
        observeReview.observe(reviewStepElement);
        observeLastStep.observe(lastStepElement);
      }
    });

    onUnmounted(() => {
      UIController.Instance.displayCenterDrawer = false;

      if (firstStepElement && reviewStepElement && lastStepElement) {
        observerFirstStep.unobserve(firstStepElement);
        observeReview.unobserve(reviewStepElement);
        observeLastStep.unobserve(lastStepElement);
      }
    });

    const clearError = () => {
      errorMessage.value = '';
    };

    const handleCancelAction = () => {
      emit('steps-complete');
      if (props.cancelAction) {
        props.cancelAction();
      }
    };

    watch(currentStep, () => {
      if (displayableSteps.value[currentStep.value]?.hasCenterContent) {
        UIController.Instance.displayCenterDrawer = true;
      } else {
        UIController.Instance.displayCenterDrawer = false;
      }
    });

    return {
      errorMessage,
      currentStep,
      isReviewStep,
      isActive,
      inReview,
      stepForward,
      stepBackwards,
      setCurrentStep,
      repeatStep,
      displayableSteps,
      stepErrors,
      isScrolledDown,
      isScrolledUp,
      isScrolledUpReview,
      clearError,
      handleCancelAction,
    };
  },
});
