import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fef165a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "members" }
const _hoisted_2 = { class: "members__header" }
const _hoisted_3 = { class: "members__table" }
const _hoisted_4 = { class: "user-data" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = {
  key: 1,
  class: "user-letter"
}
const _hoisted_7 = { class: "user-info" }
const _hoisted_8 = { class: "name" }
const _hoisted_9 = { class: "email" }
const _hoisted_10 = {
  key: 1,
  class: "role-display"
}
const _hoisted_11 = { class: "dialog-body" }
const _hoisted_12 = {
  key: 1,
  class: "empty"
}
const _hoisted_13 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_role_selector = _resolveComponent("role-selector")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(" Members "),
      (_ctx.isAdmin)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            icon: "Plus",
            type: "primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addMemberDialogVisible = true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Add Member ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_table, {
        data: _ctx.users,
        height: "100%"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            label: "User",
            sortable: "",
            "sort-by": ['name', 'email']
          }, {
            default: _withCtx(({ row: user }: { row: User }) => [
              _createElementVNode("div", _hoisted_4, [
                (user.profileImage)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "user-image",
                      onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fallBack && _ctx.fallBack(...args))),
                      src: user.profileImage,
                      alt: `${user.name} user image`
                    }, null, 40, _hoisted_5))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(user.name[0].toUpperCase()), 1)),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(user.name), 1),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(user.email), 1)
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Access Level",
            sortable: "",
            "sort-by": ['role']
          }, {
            default: _withCtx(({ row: user }: { row: User }) => [
              (_ctx.isAdmin)
                ? (_openBlock(), _createBlock(_component_role_selector, {
                    key: 0,
                    user: user
                  }, null, 8, ["user"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.getUserRoleLabel(user)), 1))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.addMemberDialogVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addMemberDialogVisible) = $event)),
      title: "Add Member",
      width: "30%"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_el_button, {
            type: "text",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addMemberDialogVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Cancel ")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            disabled: !_ctx.userIdToAdd,
            onClick: _ctx.handleAddMember
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Add Member ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          (_ctx.usersWithoutAccess.length > 0)
            ? (_openBlock(), _createBlock(_component_el_select, {
                key: 0,
                modelValue: _ctx.userIdToAdd,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userIdToAdd) = $event)),
                placeholder: "Select a user",
                size: "large",
                filterable: "",
                autocomplete: "on",
                width: "100%"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersWithoutAccess, (user) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: user.id,
                      label: `${user.name} <${user.email}>`,
                      value: user.id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, "All users already have access."))
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}