export interface PolicyAction {
  id: string;
  action: string;
}

export interface Policy {
  id: string;
  orgId: string;
  companyId: string;
  userId: string;
  policyActions: PolicyAction[];
}

export interface PolicyAction {
  action: string;
  id: string;
}

export interface User {
  id: string;
  name: string;
  email: string;
  orgId?: string;
  profileImage: string;
  companyLogo: string;
  policies: Policy[];
  roleIds: string[];
  totpEnabled: boolean;
}

export enum UserRoles {
  ADMIN = 'policy:write',
  MEMBER = 'console:access',
  NONE = 'noaccess',
}

export interface UserWithRole extends User {
  role: string;
  rolePolicy: UserRoles | null;
}

export function nullUser() {
  return {
    id: '',
    name: '',
    email: '',
    profileImage: '',
    companyLogo: '',
    policies: [],
    roleIds: [],
    totpEnabled: false,
  };
}
