
import { defineComponent, computed, ref, watchEffect } from 'vue';
import * as NAMES from '@/router/names';
import ActionController from '@/clients/action';
import OpsController from '@/clients/ops';
import UIController from '@/clients/ui';
import { useRouter, useRoute } from 'vue-router';
import artificialIcon from '@/assets/icons/artificialLogoBlack.svg';
import tecanLogo from '@/assets/tecan_logo.svg';

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const currentPath = computed(() => {
      return route.name || route.path;
    });

    const expertMode = computed(() => UIController.Instance.expert);

    const useTecanBranding =
      UIController.Instance.hasFeatureFlag('USE_TECAN_BRANDING');

    const companyName = useTecanBranding ? 'Tecan' : 'Artificial';
    const companyIcon = useTecanBranding ? tecanLogo : artificialIcon;

    const labId = computed(() =>
      Array.isArray(route.params.labId)
        ? route.params.labId[0]
        : route.params.labId
    );
    const workflowId = computed(() =>
      Array.isArray(route.params.workflowId)
        ? route.params.workflowId[0]
        : route.params.workflowId
    );

    const jobId = Array.isArray(route.params.jobId)
      ? route.params.jobId[0]
      : route.params.jobId;

    const parentName = ref(companyName);
    const showCopyLabIdButton = ref(false);
    const childName = ref('');
    const defaultReturnPath = ref('');

    const hideFilepathRoutes = [
      NAMES.HOME_ROUTE,
      NAMES.LAB_OPS,
      NAMES.RESULTS_ROUTE,
      NAMES.ASSISTANTS_ROUTE,
      NAMES.LABS_ROUTE,
      NAMES.WORKFLOWS_ROUTE,
      NAMES.NO_ACCESS,
    ];
    const isHidden = computed(() =>
      hideFilepathRoutes.includes(currentPath.value.toString())
    );

    const goBack = () => {
      if (defaultReturnPath.value) {
        router.push(defaultReturnPath.value);
      } else {
        router.back();
      }
      defaultReturnPath.value = '';
    };

    const goHome = () => {
      router.push('/');
    };

    // const portalHasContent = (target) => Wormhole.hasContentFor(target);

    watchEffect(async () => {
      showCopyLabIdButton.value = false;

      switch (currentPath.value.toString()) {
        case NAMES.LAB_OPS:
          parentName.value = companyName;
          childName.value = 'LabOps';
          return;
        case NAMES.OPS:
          parentName.value =
            (await OpsController.Instance.getLab(labId.value || '')?.name) ||
            'LabOps';
          childName.value = '';
          showCopyLabIdButton.value = true;
          defaultReturnPath.value = '/';
          return;
        case NAMES.LABS_ROUTE:
          parentName.value = companyName;
          childName.value = 'Labs';
          defaultReturnPath.value = '/';
          return;
        case NAMES.ASSISTANTS_ROUTE:
          parentName.value = companyName;
          childName.value = 'Assistants';
          defaultReturnPath.value = '/';
          return;
        case NAMES.WORKFLOWS_ROUTE:
          parentName.value = companyName;
          childName.value = 'Workflows';
          defaultReturnPath.value = '/';
          return;
        case NAMES.WORKFLOW_DETAILS:
          parentName.value = 'Workflows';
          childName.value = ActionController.Instance.getAction(
            workflowId.value || ''
          ).name;
          defaultReturnPath.value = '/workflows';
          return;
        case NAMES.DATA_RECORDS_ROUTE:
          parentName.value = 'Data Records';
          childName.value =
            (await OpsController.Instance.getJob(jobId).name) || 'Details';
          defaultReturnPath.value = '/results';
          return;
        case NAMES.REQUEST_PARAMS_ROUTE:
          await OpsController.Instance.dispatchGetJobDetailsData(jobId);
          parentName.value =
            (await OpsController.Instance.getJob(jobId).name) || 'Data Records';
          childName.value =
            (route.query.property as string) || 'Request Parameters';
          return;
        case NAMES.LAB_ROUTE:
          parentName.value = route.query.assistantId ? 'Assistants' : 'Labs';
          childName.value = route.query.assistantId
            ? ActionController.Instance.getAction(
                route.query.assistantId as string
              ).name || 'Assistant'
            : OpsController.Instance.getLab(route.query.labId as string).name ||
              'Lab';
          defaultReturnPath.value = '/';
          return;
        case NAMES.LAB_EDITOR_ROUTE:
          {
            let parent = OpsController.Instance.getLab(
              route.query.labId as string
            ).name;
            let child = 'Digital Twin Editor';
            let view = 'lab';
            if (route.query.assistantId) {
              child = ActionController.Instance.getAction(
                route.query.assistantId as string
              ).name;
              view = 'assistants';
            } else if (route.query.workflow) {
              child = ActionController.Instance.getAction(
                route.query.workflow as string
              ).name;
              view = 'workflows';
            }
            parentName.value = parent;
            childName.value = child;
            defaultReturnPath.value = router.resolve({
              name: NAMES.OPS,
              params: { labId: route.query.labId as string },
              query: { view },
            }).fullPath;
          }
          return;
        case NAMES.JOB_DETAILS_ROUTE:
          parentName.value = 'Results';
          childName.value = 'Job Details';
          defaultReturnPath.value = '/';
          return;
        case NAMES.TOKENS_ROUTE:
          parentName.value = companyName;
          childName.value = 'Tokens';
          return;
        default:
          parentName.value = companyName;
          childName.value = route.name?.toString().toUpperCase() || '--';
          return;
      }
    });

    const copyLabId = () => navigator.clipboard.writeText(labId.value);

    return {
      useTecanBranding,
      expertMode,
      currentPath,
      companyIcon,
      parentName,
      showCopyLabIdButton,
      childName,
      goBack,
      goHome,
      isHidden,
      copyLabId,
      // portalHasContent,
    };
  },
});
