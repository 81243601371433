
import { defineComponent, computed, ref, Ref, ComputedRef, watch } from 'vue';
import { OutputUnit } from '@hyperjump/json-schema/draft-07';
import ActionController, { Action } from '@/clients/action';
import OpsController, { Job } from '@/clients/ops';
import NotificationsController from '@/clients/notifications';
import Wizard, { Step } from '../RequestCreation/Wizard.vue';
import ParameterValueInfo from '../RequestCreation/ParameterValueInfo.vue';
import WorkflowRequest from '../RequestCreation/WorkflowRequest.vue';
import {
  SimpleResponse,
  InteractivityHookStage,
} from '@/components/GenericForm/types';
import { generateJobName } from '../../utils';

export default defineComponent({
  props: {
    labId: {
      type: String,
      required: true,
    },
  },
  components: {
    Wizard,
  },
  setup(props, { emit }) {
    const selectedWorkflow: Ref<Action | null> = ref(null);
    const parameterValues: Ref<SimpleResponse> = ref({});
    let requestModuleData: SimpleResponse = {};
    const validResponseData = ref(false);
    const jobToSchedule: Ref<Job | null> = ref(null);

    const workflowSchema = computed(() => {
      if (selectedWorkflow.value) {
        return ActionController.Instance.getActionSchema(
          selectedWorkflow.value.id,
          InteractivityHookStage.INTERACTIVITY_HOOK_PRE_CREATE,
          true
        );
      }
    });

    const handleJobModuleDataUpdate = ({
      responseData,
      validation,
    }: {
      responseData: SimpleResponse;
      validation: OutputUnit;
    }) => {
      if (responseData) {
        requestModuleData = responseData;
      }
      if (validation) {
        validResponseData.value = validation.valid;
      }
    };

    const schedulingFailed = ref(false);
    const createJob = async () => {
      if (
        selectedWorkflow.value &&
        !jobToSchedule.value &&
        !schedulingFailed.value
      ) {
        const jobName = await generateJobName(selectedWorkflow.value.name);
        const job = await OpsController.Instance.dispatchCreateJobFromAction(
          jobName,
          '',
          selectedWorkflow.value.id,
          undefined,
          [],
          props.labId,
          requestModuleData
        );
        parameterValues.value = requestModuleData;
        if (job) {
          jobToSchedule.value = job;
        }
        return job;
      } else if (jobToSchedule.value) {
        return jobToSchedule.value;
      }
    };

    const scheduleJob = async () => {
      if (jobToSchedule.value) {
        try {
          await OpsController.Instance.dispatchScheduleJob(
            jobToSchedule.value.id,
            [jobToSchedule.value.id],
            'time',
            (Date.now() / 1000).toString(),
            ''
          );
        } catch (e) {
          schedulingFailed.value = true;
          throw e;
        }
      }
    };

    const createAndScheduleJob = async (): Promise<Job | null> => {
      const newJob = await createJob();
      if (newJob) {
        await scheduleJob();
        return newJob;
      }
      return null;
    };

    const clearIterativeState = () => {
      jobToSchedule.value = null;
      schedulingFailed.value = false;
      requestModuleData = {};
    };

    const clearState = () => {
      clearIterativeState();
      selectedWorkflow.value = null;
    };

    const handleCompletion = async () => {
      const newJob = await createAndScheduleJob();
      if (newJob) {
        clearState();
        emit('close', newJob);
      }
    };

    // @ts-ignore
    const quickSteps: ComputedRef<Step[]> = computed(() => {
      return [
        {
          id: 'typeSelection',
          name: 'Request Type',
          props: {
            labId: props.labId,
            forJob: false,
            isWorkflow: true,
            forQuick: true,
            selectedAction: selectedWorkflow.value,
          },
          component: WorkflowRequest,
          displayCondition: true,
          disabledCondition: false,
          action: (workflow: Action) => {
            selectedWorkflow.value = workflow;
          },
          hideNext: true,
          fullHeight: true,
        },
        {
          id: 'createAndRun',
          name: 'Create and Run',
          props: {
            schema: workflowSchema.value,
            data: parameterValues.value,
          },
          component: ParameterValueInfo,
          repeatable: true,
          displayCondition: true,
          disabledCondition: computed(() => !validResponseData.value).value,
          hasCenterContent: true,
          action: handleJobModuleDataUpdate,
          repeat: async () => {
            const newJob = await createAndScheduleJob();
            if (newJob) {
              clearIterativeState();
              NotificationsController.Instance.addNewToast(
                `${newJob?.name} created`,
                props.labId,
                false
              );
              if (selectedWorkflow.value) {
                parameterValues.value =
                  ActionController.Instance.getActionParameterValues(
                    selectedWorkflow.value.id
                  ) || {};
              }
            }
          },
        },
      ];
    });

    watch(
      () => selectedWorkflow.value,
      (workflow) => {
        if (workflow) {
          parameterValues.value =
            ActionController.Instance.getActionParameterValues(workflow.id) ||
            {};
        } else {
          parameterValues.value = {};
        }
      }
    );

    return {
      handleCompletion,
      cancelAction: () => {
        if (jobToSchedule.value) {
          OpsController.Instance.dispatchDeleteJob(jobToSchedule.value.id);
        }
        emit('close');
      },
      workflowSchema,
      parameterValues,
      quickSteps,
    };
  },
});
