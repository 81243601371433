import { reactive } from 'vue';
import * as Apollo from './service.apollo';
import { Action, ActionType, WorkflowTreeNode, nullAction } from './model';
import {
  SimpleResponse,
  RequestJSONSchema,
  InteractivityHookStage,
} from '@/components/GenericForm/types';
import {
  filterSchemaToDirection,
  parseAndFilterSchemaToHook,
} from '@/components/GenericForm/utils';
import { convertActionParameterIntegerValues } from '../parameterUtils';
import { uniq } from 'lodash';
import { refetchActionSchema } from '../ops/service.apollo';
import DeferredPromise from '../DeferredPromise';

interface StateInterface {
  actions: Action[];
}

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;
  private workflowSubComplete: DeferredPromise<void>;

  private constructor() {
    /*
     * STATE
     */
    this.state = reactive({
      actions: [],
    });
    this.workflowSubComplete = new DeferredPromise();
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  reset(): void {
    this.state.actions.splice(0, this.state.actions.length);
  }

  // ACTIONS/MUTATIONS
  public async dispatchGetWorld(): Promise<void> {
    this.startWorkflowSubscription();
    this.dispatchGetDashboardData();
  }

  private mergeActions(actions: Action[]) {
    if (this.state.actions.length) {
      actions.forEach((a) => {
        const existing = this.state.actions.find((e) => e.id === a.id);
        if (!existing) {
          this.state.actions.splice(0, 0, a);
        }
      });
    } else {
      this.state.actions.splice(0, 0, ...actions);
    }
  }

  public async dispatchGetDashboardData(): Promise<void> {
    const assistants = await Apollo.getAssistants();
    this.mergeActions(assistants);
  }

  public async dispatchGetWorkflows(): Promise<Action[]> {
    const workflows = await Apollo.getWorkflows();
    this.mergeActions(workflows);
    return workflows;
  }

  public async dispatchGetAction(actionId: string): Promise<Action> {
    let action = this.state.actions.find((a) => a.id === actionId);
    if (!action) {
      action = await Apollo.getAction(actionId);
      this.state.actions.push(action);
    }
    return action;
  }

  public async dispatchGetWorkflowTree(
    workflowId: string
  ): Promise<WorkflowTreeNode[] | null> {
    const tree = await Apollo.getWorkflowTree(workflowId);
    if (tree?.length) {
      const workflow = this.getAction(workflowId);
      workflow.workflowTree = tree;
    }
    return tree;
  }

  public async dispatchGetAssistantIsEditable(id: string): Promise<boolean> {
    const action = await Apollo.getBareAssistant(id);
    return !action.jobId;
  }

  /**
   * Delete assistant given by id.
   * MUTATION/IDEMPOTENT
   * @param id the ID of the assistant to delete
   */
  public async dispatchDeleteAssistant(id: string): Promise<void> {
    const index = this.state.actions.findIndex((a) => a.id === id);
    if (index >= 0) {
      await Apollo.deleteAssistant(id);
      // rely on workflow subscription to delete the assistant from local store
    }
  }

  /**
   * Delete a workflow given by id
   * MUTATION/IDEMPOTENT
   * @param id the ID of the workflow to delete
   */
  public async dispatchDeleteWorkflow(id: string): Promise<void> {
    const index = this.state.actions.findIndex((a) => a.id === id);
    if (index >= 0) {
      await Apollo.deleteWorkflow(id);
      // rely on workflow subscription to delete the assistant from local store
    }
  }

  /**
   * Create a new assistant
   * MUTATION
   * @param name the name of the assistant
   * @param labId the lab on which the assistant acts
   * @returns A promise containing the new assistant action
   */
  public async dispatchCreateAssistant(
    name: string,
    labId: string,
    compliant?: boolean
  ): Promise<Action> {
    const assistant = await Apollo.createAssistant(name, labId, compliant);
    // for some reason, the actionapi doesn't return created and modified timestamps
    // on a newly created action
    if (!assistant.common.createdTimestamp) {
      assistant.common.createdTimestamp = new Date().toISOString();
    }
    if (!assistant.common.modifiedTimestamp) {
      assistant.common.modifiedTimestamp = new Date().toISOString();
    }
    // rely on workflow subscription to add the assistant to local store
    return assistant;
  }

  /**
   * Create a new "low-code" workflow
   * @param name the name of the workfols
   * @param labId the lab on which the workflow acts
   * @returns A promise containing the new workflow action
   */
  public async dispatchCreateDocumentWorkflow(
    name: string,
    labId: string
  ): Promise<Action> {
    const workflow = await Apollo.createDocumentWorkflow(name, labId);
    // for some reason, the actionapi doesn't return created and modified timestamps
    // on a newly created action
    if (!workflow.common.createdTimestamp) {
      workflow.common.createdTimestamp = new Date().toISOString();
    }
    if (!workflow.common.modifiedTimestamp) {
      workflow.common.modifiedTimestamp = new Date().toISOString();
    }
    // rely on workflow subscription to add the assistant to local store
    return workflow;
  }

  /**
   * Rename an assistant
   * MUTATION/IDEMPOTENT
   * @param assistantId the ID of the assistant
   * @param name the new name
   * @returns A promise with the new name
   */
  public async dispatchRenameAssistant(
    assistantId: string,
    name: string
  ): Promise<string> {
    const assistant = this.state.actions.find((a) => a.id === assistantId);
    if (assistant) {
      const newAssistant = await Apollo.renameAssistant(assistantId, name);
      if (newAssistant.id) {
        assistant.name = newAssistant.name;
        assistant.common.revision = newAssistant.common.revision;
        if (newAssistant.common?.modifiedTimestamp) {
          // TODO: Apollo bug - for some reason, assistantapi endpoints
          // return a null timestamp.
          // Queries that are routed to apollo return them correctly
          assistant.common.modifiedTimestamp =
            newAssistant.common.modifiedTimestamp;
        }
        return name;
      }
    }
    return '';
  }

  /**
   * Sets or unsets an assistant's compliance mode
   * MUTATION/IDEMPOTENT
   * @param assistantId the assistant ID
   * @param compliant if the assistant should be compliant
   * @returns the updated assistant action
   */
  public async dispatchSetAssistantCompliance(
    assistantId: string,
    compliant: boolean
  ): Promise<Action> {
    const assistant = this.state.actions.find((a) => a.id === assistantId);
    if (assistant) {
      const newAssistant = await Apollo.setAssistantCompliance(
        assistantId,
        compliant
      );
      if (newAssistant.id) {
        assistant.assistant = {
          entrypoint: true,
          compliant,
        };
        return assistant;
      }
    }
    return nullAction();
  }

  /**
   * Refetch just an action's schema. This is used if some other actor in the system
   * might choose to modify the schema at runtime or a race condition after publishing
   * a new worfklow may sometimes result in the schema not being sent.
   * @param actionId the action ID
   */
  public async dispatchRefetchSchema(actionId: string) {
    const action = await refetchActionSchema(actionId);
    if (action.schema) {
      const existingAction = this.state.actions.find((a) => a.id === actionId);
      if (existingAction) {
        existingAction.schema = action.schema;
        existingAction.parameterValues = action.parameterValues;
      }
    }
  }

  public startWorkflowSubscription() {
    Apollo.startWorkflowSubscription(
      (
        _full: boolean,
        action: 'ADDED' | 'REMOVED' | 'UPDATED',
        workflows: Action[]
      ) => {
        if (action === 'ADDED' || action === 'UPDATED') {
          workflows.forEach((w) => {
            const existingActionIdx = this.state.actions.findIndex(
              (a) => a.id === w.id
            );
            if (existingActionIdx >= 0) {
              this.state.actions.splice(existingActionIdx, 1, {
                ...this.state.actions[existingActionIdx],
                ...w,
              });
            } else {
              this.state.actions.push(w);
            }
          });
          this.workflowSubComplete.resolve();
        } else if (action === 'REMOVED') {
          workflows.forEach((w) => {
            const existingActionIdx = this.state.actions.findIndex(
              (a) => a.id === w.id
            );
            if (existingActionIdx >= 0) {
              this.state.actions.splice(existingActionIdx, 1);
            }
          });
        }
      }
    );
  }

  // GETTERS
  get actions(): Action[] {
    // Should really be called "top-level definitional actions"
    return this.state.actions.filter((a) => !a.definedFrom?.id && !a.parentId);
  }

  get assistants(): Action[] {
    return this.actions.filter((a) => a.actionType === ActionType.ASSISTANT);
  }

  get workflowsForFilesTable(): Action[] {
    // filter out workflows generated from document workflows otherwise they
    // just look like duplicates
    const generatedWorkflowIds = uniq(
      this.actions
        .filter((a) => a.actionType === ActionType.DOCUMENT_WORKFLOW)
        .map((d) => d.documentWorkflow?.generatedWorkflowId)
        .filter(Boolean)
    );
    return [
      ...this.actions.filter(
        (a) =>
          (a.actionType === ActionType.WORKFLOW ||
            a.actionType === ActionType.DOCUMENT_WORKFLOW) &&
          !generatedWorkflowIds.includes(a.id)
      ),
    ];
  }

  get workflows(): Action[] {
    return this.actions.filter((a) => a.actionType === ActionType.WORKFLOW);
  }

  get recurringActions(): Action[] {
    return this.actions.filter(
      (a) =>
        a.actionType === ActionType.RECURRING ||
        a.constraint?.creation?.recurring
    );
  }

  get workflowSubCompletePromise(): Promise<void> {
    return this.workflowSubComplete;
  }

  getCreationConstrainedWorkflows(
    creationConstraint:
      | 'fromFile'
      | 'interactive'
      | 'quick'
      | 'recurring'
      | 'standalone'
      | 'programmaticallySchedulable',
    labId: string
  ): Action[] {
    return this.workflows.filter((w) => {
      if (w.constraint?.labId !== labId) {
        return false;
      }
      if (creationConstraint === 'fromFile') {
        return !!w.constraint?.creation?.fromFile;
      } else if (creationConstraint === 'quick') {
        return !!w.constraint?.creation?.quick;
      } else if (creationConstraint === 'interactive') {
        return !!w.constraint?.creation?.interactive || !w.constraint?.creation;
      } else if (creationConstraint === 'recurring') {
        return !!w.constraint?.creation?.recurring;
      } else if (creationConstraint === 'standalone') {
        return !!w.constraint?.creation?.standalone;
      } else if (creationConstraint === 'programmaticallySchedulable') {
        return !!w.constraint?.creation?.programmaticallySchedulable;
      } else {
        return true;
      }
    });
  }

  getAssistant(id: string): Action {
    return this.assistants.find((a) => a.id === id) || nullAction();
  }

  getAction(id: string): Action {
    return this.actions.find((a) => a.id === id) || nullAction();
  }

  getActionSchema(
    actionId: string,
    hook?: InteractivityHookStage,
    input?: boolean
  ): RequestJSONSchema {
    const action = this.getAction(actionId);
    if (action) {
      return filterSchemaToDirection(
        parseAndFilterSchemaToHook(action, hook),
        input
      );
    }
    return {};
  }

  getActionParameterValues(actionId: string): SimpleResponse | null {
    const action = this.getAction(actionId);
    if (action?.parameterValues) {
      try {
        return convertActionParameterIntegerValues(action);
      } catch (_) {
        return null;
      }
    }
    return null;
  }

  getWorkflowTree(workflowId: string): WorkflowTreeNode[] {
    const workflow = this.getAction(workflowId);
    if (workflow.id) {
      return workflow.workflowTree || [];
    }
    return [];
  }
}
