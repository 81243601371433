import { getUserRole } from "@/clients/users/controller";
import { User, UserRoles } from "@/clients/users/model";

export const getUserRoleLabel = (u: User) => {
  const rolePolicy = getUserRole(u);
  switch (rolePolicy) {
    case UserRoles.ADMIN:
      return 'Admin';
    case UserRoles.MEMBER:
      return 'Member';
    case UserRoles.NONE:
      return 'No access';
    default:
      return 'Unknown';
  }
};