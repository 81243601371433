import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d2293254"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quick-schedule-job" }
const _hoisted_2 = { class: "quick-schedule-job-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wizard = _resolveComponent("wizard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_wizard, {
        steps: _ctx.quickSteps,
        completionAction: _ctx.handleCompletion,
        cancelAction: _ctx.cancelAction
      }, null, 8, ["steps", "completionAction", "cancelAction"])
    ])
  ]))
}