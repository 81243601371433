import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a0d21f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings" }
const _hoisted_2 = { class: "tree" }
const _hoisted_3 = {
  key: 0,
  class: "settings__content"
}
const _hoisted_4 = { class: "toasts-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree_view = _resolveComponent("tree-view")!
  const _component_toasts = _resolveComponent("toasts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tree_view, {
        data: _ctx.treeData,
        selected: _ctx.selectedMenuItem?.id,
        onSelectItem: _ctx.handleSelectMenuItem
      }, null, 8, ["data", "selected", "onSelectItem"])
    ]),
    (_ctx.selectedMenuItem)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selectedMenuItem.component?.value), {
            propData: _ctx.selectedMenuItem.id,
            "org-config": _ctx.orgConfig
          }, null, 8, ["propData", "org-config"]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_toasts, { context: 'settings' })
    ])
  ]))
}